import { faFacebook, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { useChartCalculations } from './charts/utils';
import { Card } from './common-ui/Card';
import { PageContainer } from './common-ui/PageContainer';
import { Section } from './common-ui/Section';
import { checkmarkStyle, commonButtonStyle, landingPageListFont } from './common-ui/styles';
import { Text } from './common-ui/Text';
import { FAQ } from './features/FAQ';
import { useProgramLevelConfigs, useMonthlyYearlyProgramLevelConfigs ,useMonthlyYearlyProgramLevelConfigs1} from './features/programs/Program.config';

import { getProgramLevelCardId, ProgramLevel } from './features/programs/ProgramLevel';
import { Footer } from './Footer';
import { AreaBody } from './quiz/areaBody';
import { BusyLevel } from './quiz/busy';
import { Goals } from './quiz/goals';
import { Motivation } from './quiz/motivation';
import { MuscleFocus } from './quiz/muscleFocus';
import { sendViewItemListEvent } from './services/googleTagManager';
import { saveTimeToGoalToHubspot } from './services/hubspot';
import { User } from './types';
import { ProgramLevelTitle } from './features/programs/ProgramLevel.types';
import { CDN_ROOT } from './features/images';
import { useGate , useExperiment } from 'statsig-react';


import { MonthlyYearlyProgramLevel, getMonthlyYearlyProgramLevelCardId } from './features/programs/MonthlyYearlyProgram';
import { MonthlyYearlyProgramLevel1, getMonthlyYearlyProgramLevelCardId1 } from './features/programs/MonthlyYearlyProgram1';

import Statsig from 'statsig-js';
import Cookies from 'js-cookie';

const VersionDivLanding = styled.div`
  font-size:50px;
  margin:0 auto;
  width:100%;
`;

const LandingPageContainer = styled(PageContainer)`
  background-color: #ffffff;
  max-width: 1200px;
  @media (max-width: 500px) {
    padding-top: 0px;
  }
`;

const ResultsImage = styled.img`
  width: 100%;
  max-width: 250px;
  align-self: center;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    display: none;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  max-width: 600px;
  @media (max-width: 800px) {
    min-width: 100vw;
    margin-bottom: 0px;
  }
`;

const Subtitle = styled.h2`
  font-family: 'Oswald', sans-serif;
  font-size: 40px;
  color: #1e1e1e;
  text-align: center;
  margin-bottom: 10px;
  
  
  @media (max-width: 800px) {
    font-size: 26px;
    margin-bottom: 10px;
  }
`;

const SubtitleItalic = styled.h3`
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  color: #1e1e1e;
  text-align: center;
  margin-bottom: 40px;
  font-style:italic;
  margin-top:10px;
  
  
  @media (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const PlanList = styled.ul`
  padding-inline-start: 0;
`;

const ProgramLevelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0;

  @media (max-width: 800px) {
    margin-top: 48px;
    flex-direction: column;
    gap: 50px;
  }
`;

const StartNowButton = styled.button`
  ${commonButtonStyle}
`;

const RestartQuizButton = styled.button`
  text-decoration: underline;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  font-size: 16px;
`;

const PersonalPlan = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  li {
    list-style: none;
    position: relative;
    margin-left: -2em;
    padding-left: 2em;
    ${landingPageListFont}
    ${checkmarkStyle}
    margin-bottom: 10px;
  }
  ${StartNowButton} {
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    margin-left: 30px;
  }
`;

const PersonalizedSection = styled(Card)`
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 5px;
  width: 90%;
  @media (max-width: 1160px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  div {
    flex: 1;
    flex-basis: 50%;

    @media (min-width: 768px) {
      min-width: 500px;
    }
  }
`;

const TileImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const NewsContainer = styled(TileImageContainer)`
  justify-content: space-around;
  @media (min-width: 767px) {
    row-gap: 30px;
  }
`;

const NewsImage = styled.img`
  min-width: 50px;
  max-width: 45%;
  height: 40px;
  margin: 0 10px;
  opacity: 0.3;

  @media (min-width: 767px) {
    width: auto;
  }
`;

const SocialsWrapper = styled(TileImageContainer)`
  margin-top:30px;
`;

const SocialLink = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 80%;
  justify-content: space-evenly;
  padding: 10px;
  margin: 10px;
  box-shadow:rgba(0, 0, 0, 0.15) 0px 5px 15px 5px;
  border-radius: 10px;
  min-width: 270px;
  @media (min-width: 768px) {
    width: auto; /* Reset width to auto on larger screens */

  }
  svg {
    margin-right: 8px;
  }
`;

const SubscriberCount = styled.span`
  font-size: 24px;
  font-weight:600;
`;


const SocialIconImage = styled.img`
  width: 100%;
  max-width: 60px;
  align-self: center;
  padding:5px;
  @media (max-width: 500px) {
    
  }
`;
const GuaranteeImage = styled.img`
  width: 100%;
  max-width: 300px;
  align-self: center;
  margin-bottom: 20px;
 
`;

const SocialText=styled.div`
  font-size:20px;
  font-family:Lato;
  font-weight:400;
`;
const TextLanding = styled.p`
  font-size: 20px;
  color: #1E1E1E;
  text-align: left;
  width:100%;
  max-width:1200px;
  margin:0 auto;
  @media (max-width: 800px) {
    line-height: 1.4;
    padding-bottom: 20px;
  }
`;
const TextLandingCentered= styled.p`
font-size: 20px;
color: #1E1E1E;
text-align: center;
width:100%;
max-width:1200px;
margin:0 auto;
@media (max-width: 800px) {
  line-height: 1.4;
  padding-bottom: 20px;
}
`;

const SectionMaxWidth = styled.div`
 
  width: 100%;
  max-width:1200px;
  margin:0 auto;
  @media (max-width: 800px) {
    padding-bottom:20px;
  }
 
`;

const GuaranteeSection = styled.div`
  display:flex;
  padding:20px;
  @media only screen and (max-width: 600px){
  flex-direction: column;
  }
`;


const SocialIconsNew = () => {
  return (
    <SocialsWrapper>
      <SocialLink>
        <SocialIconImage src="https://builtwithscience.com/wp-content/uploads/2024/12/Users.png" alt="Users" />
        <SubscriberCount>200,000+<SocialText>Transformations</SocialText></SubscriberCount>
      </SocialLink>
   
      <SocialLink>
        <SocialIconImage src="https://builtwithscience.com/wp-content/uploads/2024/12/Youtube.png" alt="YouTube" />
        <SubscriberCount>6.9M+<SocialText>Youtube Subscribers</SocialText></SubscriberCount>
      </SocialLink>
      <SocialLink>
        <SocialIconImage src="https://builtwithscience.com/wp-content/uploads/2024/12/Research.png" alt="Research" />
        <SubscriberCount>$100,000+<SocialText>Funded To Research</SocialText></SubscriberCount>
       
      </SocialLink>
   
    </SocialsWrapper>
  );
};


const Socials = () => {
  return (
    <SocialsWrapper>
      <SocialLink>
        <FontAwesomeIcon icon={faFacebook} style={{ color: '#b1b1b1' }} size={'4x'} />
        <SubscriberCount>600k+</SubscriberCount>
      </SocialLink>
      <SocialLink>
        <FontAwesomeIcon icon={faYoutube} style={{ color: '#b1b1b1' }} size={'4x'} />
        <SubscriberCount>5.5M+</SubscriberCount>
      </SocialLink>
      <SocialLink>
        <FontAwesomeIcon icon={faInstagram} style={{ color: '#b1b1b1' }} size={'4x'} />
        <SubscriberCount>850k+</SubscriberCount>
      </SocialLink>
      <SocialLink>
        <FontAwesomeIcon icon={faTiktok} style={{ color: '#b1b1b1' }} size={'4x'} />
        <SubscriberCount>550k+</SubscriberCount>
      </SocialLink>
    </SocialsWrapper>
  );
};

function joinWithAnd(arr: string[]): string {
  const length = arr.length;
  if (length === 0) {
    return '';
  } else if (length === 1) {
    return arr[0];
  }
  return arr.slice(0, length - 1).join(', ') + ' and ' + arr[length - 1];
}

const createGoalAreaString = (goals: Goals, areaBody: string[], muscleFocus: string[]) => {
  const muscleText =
    muscleFocus.length === 1 && muscleFocus[0] === MuscleFocus.Equally
      ? 'across all muscle groups'
      : 'especially in the ' + joinWithAnd(muscleFocus.filter((item) => MuscleFocus.Equally !== item).map((item) => item.toLocaleLowerCase()));
  const fatLossText =
    areaBody.length === 1 && areaBody[0] === AreaBody.Equally
      ? 'evenly across your entire body'
      : 'in the ' + joinWithAnd(areaBody.filter((item) => AreaBody.Equally !== item).map((item) => item.toLocaleLowerCase()));
  switch (goals) {
    case Goals.BuildMuscleAndLoseFat:
      return `Focus on losing stubborn fat ${fatLossText} while building muscle ${muscleText}`;
    case Goals.BuildMuscle:
      return `Focus on building muscle ${muscleText}`;
    case Goals.LoseFat:
      return `Lose stubborn fat ${fatLossText}`;
  }
};

const MOTIVATION_TO_TEXT = {
  [Motivation.CONFIDENCE]: 'Feel more confident about yourself and your body',
  [Motivation.HEALTH]: 'Become healthier',
  [Motivation.FEEL_BETTER]: 'Feel better day-to-day',
  [Motivation.PROVE_CAPABLE]: `Prove to yourself that you're capable of more`,
};

type LandingPageProps = {
  user: User;
  onComplete: () => void;
  TEST_USE_DURATIONS?: boolean;
};

function get_ga_clientid() {
  const gaCookie = Cookies.get('_ga');
  if (!gaCookie) {
    return null;
  }
  return gaCookie.split('.').slice(2).join('.');
}

function getAmplitudeDeviceId() {
  return Cookies.get('bws_amplitude_device_id');
}

const LandingPage = (props: LandingPageProps) => {
  const { user, TEST_USE_DURATIONS = false } = props;
  const { chart, timeToGoal } = useChartCalculations(user);
  const shouldUseStripe = 0;
  const experiment = useExperiment('quiz_test_801010');
  const experiment_version=experiment.config.value.Version;

  const { base: regBase, premium: regPremium, elite: regElite, listName: regListName } = useProgramLevelConfigs(user);
  const { base: stripeBase, premium: stripePremium, elite: stripeElite, listName: stripeListName } = useMonthlyYearlyProgramLevelConfigs(user);
  const { base: stripeBase1, premium: stripePremium1, elite: stripeElite1, listName: stripeListName1 } = useMonthlyYearlyProgramLevelConfigs1(user);


  const programs = useMemo(() => {
    const stableId = Statsig.getStableID();
    let stableIdParam = '';
    if (stableId != null) {
      stableIdParam = `stableId=${stableId}`;
    }
    const gaClientId = get_ga_clientid();
    let gaParam = '';
    if (gaClientId != null) {
      gaParam = `ga_id=${gaClientId}`;
    }
    const amplitudeId = getAmplitudeDeviceId();
    console.log('Amplitude Device ID: ', amplitudeId);
    let amplitudeParam = '';
    if (amplitudeId) {
      amplitudeParam = `amplitude_id=${amplitudeId}`;
    }
    const idParams = [stableIdParam, gaParam, amplitudeParam].filter((p) => p.length > 0).join('&');
    stripeBase.buyLink = `${stripeBase.buyLink}&${idParams}`; // ? to & because for base we are using shopify checkout links
    stripePremium.buyLink = `${stripePremium.buyLink}?${idParams}`;
    stripeElite.buyLink = `${stripeElite.buyLink}?${idParams}`;

    stripeBase1.buyLink = `${stripeBase1.buyLink}&${idParams}`; // ? to & because for base we are using shopify checkout links
    stripePremium1.buyLink = `${stripePremium1.buyLink}?${idParams}`;
    stripeElite1.buyLink = `${stripeElite1.buyLink}?${idParams}`;
    // TODO just add this at the button level when we remove the old program level
    switch (experiment_version) {
      case 1:
          return [stripeBase, stripePremium, stripeElite];
      case 2:
          return  [stripeBase1, stripePremium1, stripeElite1];
      default:
          return [regBase, regPremium, regElite];
  }
    
  }, [shouldUseStripe, stripeBase, stripePremium, stripeElite, stripeBase1, stripePremium1, stripeElite1, regBase, regPremium, regElite]);
  let listName =  regListName;

  switch (experiment_version) {
    case 1:
       
      listName = stripeListName;
      break;
    case 2:
      
      listName = stripeListName1;
      break;
    default:
      
      listName =  regListName;
      break;
}

  useEffect(() => {
    saveTimeToGoalToHubspot(user, timeToGoal.toDuration('months').months);
  }, [user, timeToGoal]);

  const goalAreaText = useMemo(() => createGoalAreaString(user.goals, user.areaBody, user.muscleFocus), [user.goals, user.areaBody, user.muscleFocus]);

  useEffect(() => {
    const itemEvent = {
      itemListName: listName,
      items: programs.map((item) => ({
        itemId: item.productId,
        originalPrice: item.price.original,
        price: item.price.current,
        itemName: item.name,
      })),
    };
    sendViewItemListEvent(itemEvent);
  }, [programs, listName, TEST_USE_DURATIONS]);

  const plan = [
    `Go from ${user.bodytype?.toLocaleLowerCase() || 'your body type'} to lean, muscular, and athletic`,
    ...(user.motivation ? [MOTIVATION_TO_TEXT[user.motivation]] : []),
    goalAreaText,
    `With customizations for your equipment${user.injuries[0] !== 'none' ? ' and injuries' : ''}`,
    `Tailored to your${[BusyLevel.FairlyBusy, BusyLevel.NoTime].includes(user.busyLevel) ? ' busy' : ''} lifestyle`,
  ];

  const handleClickScroll = () => {
    let elementId = getProgramLevelCardId(ProgramLevelTitle.Elite);
    
    switch (experiment_version) {
      case 1:
         
        elementId = getMonthlyYearlyProgramLevelCardId('Annual');
        break;
      case 2:
        
        elementId = getMonthlyYearlyProgramLevelCardId1('Annual');
        break;
      default:
        
        elementId = getProgramLevelCardId(ProgramLevelTitle.Elite);
        break;
  }
  
   
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  let startNowButton = <StartNowButton onClick={handleClickScroll}>
      START NOW
    </StartNowButton>;

  
  let programLevels =  [regBase, regPremium, regElite].map((item, i) => <ProgramLevel key={i} {...item} />);

  let subtitle = `Choose your Program Level`;

    switch (experiment_version) {
      case 1:
         
         startNowButton = <StartNowButton onClick={handleClickScroll}>
           START NOW
          </StartNowButton>;

           programLevels = 
           [stripeBase, stripePremium, stripeElite].map((item, i) => <MonthlyYearlyProgramLevel key={i} {...item} />);
           
           subtitle = `Choose Your Personalized Plan`;
        break;
      case 2:

        startNowButton = <StartNowButton onClick={handleClickScroll}>
           START FREE TRIAL
          </StartNowButton>;

        programLevels = 
        [stripeBase1, stripePremium1, stripeElite1].map((item, i) => <MonthlyYearlyProgramLevel1 key={i} {...item} />);

        subtitle = `Choose Your Personalized Plan`;
        break;
      default:
        
        startNowButton = <StartNowButton onClick={handleClickScroll}>
        START NOW
       </StartNowButton>;

        programLevels =  [regBase, regPremium, regElite].map((item, i) => <ProgramLevel key={i} {...item} />);

        subtitle = `Choose your Program Level`;
        break;
  }

  console.log("experimentLandingPage",experiment.config);
 
  if(experiment_version === 1){
    // Version Quiz 1 110%

    return (
      <>
      
      <LandingPageContainer>
        <ResultsImage src="https://builtwithscience.com/wp-content/uploads/2022/12/BuiltWithScience-Blue-1.png" alt="Your Results are In" />
        <PersonalizedSection>
          <ChartWrapper>{chart}</ChartWrapper>
          <PersonalPlan>
            <Subtitle>{`${props.user.name}'s personalized program:`}</Subtitle>
            <PlanList>
              {plan
                .filter((p) => p != null)
                .map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
            </PlanList>
            {startNowButton}
          </PersonalPlan>
        </PersonalizedSection>
        <Section>
          <Subtitle>Our Transformations Regularly Make Headlines</Subtitle>
          <NewsContainer>
            <NewsImage src={`${CDN_ROOT}/images/mensHealth.jpg`} alt="Men's Health" />
            <NewsImage src={`${CDN_ROOT}/images/ktla.webp`} alt="KTLA" />
            <NewsImage src={`${CDN_ROOT}/images/yahoo.jpg`} alt="Yahoo" />
            <NewsImage src={`${CDN_ROOT}/images/womensFitness.jpg`} alt="Women's Fitness" />
          </NewsContainer>
        </Section>
        <Section id="program-levels">
          <Subtitle>{subtitle}</Subtitle>
          <SubtitleItalic>Save Up To 50% Off, Locked In For Life.</SubtitleItalic>
          <ProgramLevelsContainer>{programLevels}</ProgramLevelsContainer>
        </Section>
        <SectionMaxWidth>
          <Subtitle>Transform Your Body In 90 Days Or We'll Pay You 110%</Subtitle>
          <GuaranteeSection>
          <TextLanding>
          We're so confident with BWS+ and the results it delivers, that we offer a 110% risk-free money-back guarantee. Our guarantee is simple, if you don't achieve your transformation in 90 days we'll refund you 100% of your money back PLUS an extra 10% on top of it!
<br></br><br></br>
<strong>No other company stands behind their products like we do.</strong>
<br></br><br></br>
Why are we this confident? Because we know the results speak for themselves when you commit to the process. All we ask is that you complete at least 20 workouts in the app during the 90 days to give yourself a real shot at success. 
<br></br><br></br>
If you don’t see results after that, the extra 10% is on us.
          </TextLanding>
          <GuaranteeImage src="https://builtwithscience.com/wp-content/uploads/2024/12/image-25.png" alt="110% guarantee" />
          </GuaranteeSection>
        </SectionMaxWidth>
        {startNowButton}
        <SocialIconsNew />
        <Section>
          <Subtitle>Frequently Asked Questions</Subtitle>
          <FAQ />
        </Section>
       
        {startNowButton}
        <RestartQuizButton
          onClick={() => {
            localStorage.removeItem('BWS_SURVEY_DATA');
            window.location.href = '/page/1';
          }}
        >
          Restart Quiz
        </RestartQuizButton>
      </LandingPageContainer>
      <Footer />
      </>
    );
  }else if(experiment_version === 2) {
    // Version Quiz 2 trial
    return (
      <>
       
      <LandingPageContainer>
        <ResultsImage src="https://builtwithscience.com/wp-content/uploads/2022/12/BuiltWithScience-Blue-1.png" alt="Your Results are In" />
        <PersonalizedSection>
          <ChartWrapper>{chart}</ChartWrapper>
          <PersonalPlan>
            <Subtitle>{`${props.user.name}'s personalized program:`}</Subtitle>
            <PlanList>
              {plan
                .filter((p) => p != null)
                .map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
            </PlanList>
            {startNowButton}
          </PersonalPlan>
        </PersonalizedSection>
        <Section>
          <Subtitle>Our Transformations Regularly Make Headlines</Subtitle>
          <NewsContainer>
            <NewsImage src={`${CDN_ROOT}/images/mensHealth.jpg`} alt="Men's Health" />
            <NewsImage src={`${CDN_ROOT}/images/ktla.webp`} alt="KTLA" />
            <NewsImage src={`${CDN_ROOT}/images/yahoo.jpg`} alt="Yahoo" />
            <NewsImage src={`${CDN_ROOT}/images/womensFitness.jpg`} alt="Women's Fitness" />
          </NewsContainer>
        </Section>
        <Section id="program-levels">
          <Subtitle>{subtitle}</Subtitle>
          <SubtitleItalic>Save Up To 50% Off, Locked In For Life.</SubtitleItalic>
          <ProgramLevelsContainer>{programLevels}</ProgramLevelsContainer>
        </Section>
        <SectionMaxWidth>
          <Subtitle>Unlock Your BWS+ Plan FREE For 14 Days</Subtitle>
       
          <TextLandingCentered>
          Unlock 14 days of personalized coaching with BWS+. 
          <br></br>You’ll only be charged after your trial ends, and you can cancel anytime – no hassle, no commitment.

          </TextLandingCentered>
                </SectionMaxWidth>
        {startNowButton}
        <SocialIconsNew />
        <Section>
          <Subtitle>Frequently Asked Questions</Subtitle>
          <FAQ />
        </Section>
       
        {startNowButton}
        <RestartQuizButton
          onClick={() => {
            localStorage.removeItem('BWS_SURVEY_DATA');
            window.location.href = '/page/1';
          }}
        >
          Restart Quiz
        </RestartQuizButton>
      </LandingPageContainer>
      <Footer />
      </>
    );
  }else{
    //Detault Programs
  
  return (
    <>
      <LandingPageContainer>
        <ResultsImage src="https://builtwithscience.com/wp-content/uploads/2022/12/BuiltWithScience-Blue-1.png" alt="Your Results are In" />
        <PersonalizedSection>
          <ChartWrapper>{chart}</ChartWrapper>
          <PersonalPlan>
            <Subtitle>{`${props.user.name}'s personalized program:`}</Subtitle>
            <PlanList>
              {plan
                .filter((p) => p != null)
                .map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
            </PlanList>
            {startNowButton}
          </PersonalPlan>
        </PersonalizedSection>
        <Section>
          <Subtitle>Our Transformations Regularly Make Headlines</Subtitle>
          <NewsContainer>
            <NewsImage src={`${CDN_ROOT}/images/mensHealth.jpg`} alt="Men's Health" />
            <NewsImage src={`${CDN_ROOT}/images/ktla.webp`} alt="KTLA" />
            <NewsImage src={`${CDN_ROOT}/images/yahoo.jpg`} alt="Yahoo" />
            <NewsImage src={`${CDN_ROOT}/images/womensFitness.jpg`} alt="Women's Fitness" />
          </NewsContainer>
        </Section>
        <Section id="program-levels">
          <Subtitle>{subtitle}</Subtitle>
          <Subtitle></Subtitle>
          <ProgramLevelsContainer>{programLevels}</ProgramLevelsContainer>
        </Section>
        <Section>
          <Subtitle>{shouldUseStripe ? 'Enjoy Your First 14 Days Free with BWS+ ' : '100% Hassle-Free -90 Day Money Back Guarantee'}</Subtitle>
          <Text>
            {shouldUseStripe
              ? 'Unlock a free 14-day trial when you join BWS+. You’ll only be charged after your trial ends, and you can cancel anytime – no hassle, no commitment.'
              : "If you commit to a program and don't see results in 90-days, we'll refund your money. No questions asked. "}
          </Text>
        </Section>
        {startNowButton}
        <SocialIconsNew />
        <Section>
          <Subtitle>Frequently Asked Questions</Subtitle>
          <FAQ />
        </Section>
       
        {startNowButton}
        <RestartQuizButton
          onClick={() => {
            localStorage.removeItem('BWS_SURVEY_DATA');
            window.location.href = '/page/1';
          }}
        >
          Restart Quiz
        </RestartQuizButton>
      </LandingPageContainer>
      <Footer />
    </>
  );
        }
};

export { LandingPage };
